import React, { useState, useRef, Component, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import axios from "axios";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Button,
  Input,
  InputGroupText,
  InputGroup,
  Spinner,
  Pagination,
  PaginationItem,
  PaginationLink,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  CardText,
  Badge,
} from "reactstrap";
import "./Knowledgebase.css";
import { CiFileOn } from "react-icons/ci";
import { FiLink } from "react-icons/fi";
import { FiThumbsUp } from "react-icons/fi"; // Import thumbs up icon for "like"
import { FiEye } from "react-icons/fi"; // Import thumbs up icon for "like"
import {
  FaFilePdf,
  FaFileWord,
  FaFilePowerpoint,
  FaFileImage,
  FaFileAlt,
} from "react-icons/fa"; // Import file icons
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import S3Explorer from "./S3Explorer"; 

const KnowledgebaseMain = ({ fileData = [] }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedTitle, setSelectedTitle] = useState("");
  const [selectedLocation, setSelectedLocation] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [titleDropdownOpen, setTitleDropdownOpen] = useState(false);
  const [locationDropdownOpen, setLocationDropdownOpen] = useState(false);
  const [categoryDropdownOpen, setCategoryDropdownOpen] = useState(false);

  const [selectedId, setSelectedId] = useState("");
  const [selectedTag, setSelectedTag] = useState("");
  const [selectedFiletype, setSelectedFiletype] = useState("");
  const [searchText, setSearchText] = useState(""); // default value is an empty string

  const [idDropdownOpen, setIdDropdownOpen] = useState(false);
  const [tagDropdownOpen, setTagDropdownOpen] = useState(false);
  const [filetypeDropdownOpen, setFiletypeDropdownOpen] = useState(false);

  // For View Count
  const [videoViews, setVideoViews] = useState({});

  // Add a state for likes
  const [videoLikes, setVideoLikes] = useState({});

  const [viewsCount, setViewsCount] = useState(0);
  const [likesCount, setLikesCount] = useState(0);

  const videoRefs = useRef({});
  const [videoDurations, setVideoDurations] = useState({});

  const itemsPerPage = 9; // Display 9 cards per page 8 for kb.telepresenz.com and 9 for umd viewer

  const toggleTitleDropdown = () =>
    setTitleDropdownOpen((prevState) => !prevState);
  const toggleLocationDropdown = () =>
    setLocationDropdownOpen((prevState) => !prevState);
  const toggleCategoryDropdown = () =>
    setCategoryDropdownOpen((prevState) => !prevState);

    const navigate = useNavigate();
  
 const openS3Explorer = () => {
  window.open("/s3explorer", "_blank"); // Opens in a new tab
};
const LocationDropdown = () => {
  const [selectedLocation, setSelectedLocation] = useState("");
  const [locationDropdownOpen, setLocationDropdownOpen] = useState(false);

  const toggleLocationDropdown = () => {
    setLocationDropdownOpen((prevState) => !prevState);
  };

  useEffect(() => {
    const storedLocation = localStorage.getItem("selectedLocation");
    if (storedLocation) {
      setSelectedLocation(storedLocation);
    }
  }, []);
};

const handleLocationSelect = (location) => {
  setSelectedLocation(location);
  localStorage.setItem("selectedLocation", location);

  // Open S3 Explorer only if "Kieweit - Temporary Boilers" is selected
  if (location === "Kieweit - Temporary Boilers") {
    openS3Explorer();
  }
};


  const uniqueTitles = [...new Set(fileData.map((file) => file.title))];
  const uniqueLocations = [...new Set(fileData.map((file) => file.location))];
  const uniqueCategories = [...new Set(fileData.map((file) => file.category))];
  const filteredFiles = fileData.filter((file) => {
    const matchesSearch =
      file.title?.toLowerCase()?.includes(searchTerm.toLowerCase()) ||
      file.description?.toLowerCase()?.includes(searchTerm.toLowerCase()) ||
      file.media_files?.some((media) =>
        media.tags?.some((tag) =>
          tag.toLowerCase().includes(searchTerm.toLowerCase())
        )
      );

    const matchesTitle = selectedTitle ? file.title === selectedTitle : true;
    const matchesLocation = selectedLocation
      ? file.location === selectedLocation
      : true;
    const matchesCategory = selectedCategory
      ? file.category === selectedCategory
      : true;
    const matchesId = selectedId ? file.knowledge_base_id === selectedId : true;
    const matchesFiletype = selectedFiletype
      ? file.media_files.some(
          (media) => media.content_type === selectedFiletype
        )
      : true;
    const matchesTag = selectedTag
      ? file.media_files?.some((media) => media.tags?.includes(selectedTag))
      : true;

    return (
      matchesSearch &&
      matchesTitle &&
      matchesLocation &&
      matchesCategory &&
      matchesTag &&
      matchesId &&
      matchesFiletype
    );
  });

  const uniqueTags = Array.from(
    new Set(
      fileData.flatMap((file) =>
        file.media_files.flatMap((media) =>
          media.tags.flatMap((tag) =>
            tag.includes(",") ? tag.split(",").map((t) => t.trim()) : [tag]
          )
        )
      )
    )
  ).sort((a, b) => a.localeCompare(b)); // Sort tags alphabetically

  const totalItems = filteredFiles.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentItems = filteredFiles.slice(startIndex, endIndex);

  const handlePageChange = (pageNumber) => setCurrentPage(pageNumber);

  const handleVideoLoaded = (id) => {
    const video = videoRefs.current[id];
    if (video) {
      setVideoDurations((prevDurations) => ({
        ...prevDurations,
        [id]: video.duration.toFixed(2),
      }));
    }
  };

  const copyDeepLink = (id) => {
    const link = `${window.location.origin}/details/${id}`;
    navigator.clipboard
      .writeText(link)
      .then(() => {
        alert("Link copied to clipboard!");
      })
      .catch((err) => {
        console.error("Error copying link: ", err);
      });
  };

  // Map file types to corresponding icons
  const fileTypeIcons = {
    pdf: <FaFilePdf size={80} color="red" />,
    docx: <FaFileWord size={80} color="blue" />,
    pptx: <FaFilePowerpoint size={80} color="orange" />,
    png: <FaFileImage size={80} color="green" />,
    jpg: <FaFileImage size={80} color="green" />,
    jpeg: <FaFileImage size={80} color="green" />,
    tiff: <FaFileImage size={80} color="green" />,
    default: <FaFileAlt size={80} color="gray" />,
  };

  useEffect(() => {
    const handleContextMenu = (e) => {
      e.preventDefault();
    };

    document.body.addEventListener("contextmenu", handleContextMenu);

    return () => {
      document.body.removeEventListener("contextmenu", handleContextMenu);
    };
  }, []);

  const renderPreview = (file) => {
    // Check for video files
    const videoFile = file?.media_files?.find(
      (media) => media?.content_type?.toLowerCase() === "video"
    );

    if (videoFile) {
      return (
        <div className="card" style={{ width: "100%", border: "none" }}>
          <div className="position-relative" style={{ width: "100%" }}>
            <video
              controlsList="nodownload"
              ref={(el) => (videoRefs.current[file.knowledge_base_id] = el)}
              width="100%"
              onLoadedMetadata={() => handleVideoLoaded(file.knowledge_base_id)}
              onPlay={() => handleVideoPlay(file.knowledge_base_id)} // Trigger view count update
              controls
              src={videoFile.file_path}
            />
          </div>
        </div>
      );
    }

    // Handle other file types
    const otherFile = file?.media_files?.[0]; // Pick the first non-video file
    if (otherFile) {
      const filePath = otherFile.file_path || "";
      const fileExtension = filePath.split(".").pop()?.toLowerCase(); // Get file extension
      const icon = fileTypeIcons[fileExtension] || fileTypeIcons.default;

      return (
        <div className="d-flex flex-column align-items-center">
          {icon}
          <p style={{ marginTop: "10px", fontWeight: "500" }}>
            {fileExtension?.toUpperCase()} File
          </p>
        </div>
      );
    }

    // Default fallback if no file exists
    return <p className="text-center">Preview not found!</p>;
  };
  const handleLikeClick = async (id) => {
    try {
      // Increment likes locally
      setVideoLikes((prevLikes) => {
        const currentLikes = prevLikes[id] || 0;
        return { ...prevLikes, [id]: currentLikes + 1 };
      });

      // Send updated likes to the backend
      await axios.post(`/api/knowledgebase/${id}/like`, {
        knowledgeBaseId: id,
      });
      console.log(`Likes updated for ID: ${id}`);
    } catch (error) {
      console.error("Error updating likes:", error);
    }
  };

  const getPaginationRange = () => {
    const maxPagesToShow = 10;
    const halfRange = 5;
    let startPage = Math.max(currentPage - halfRange, 1);
    let endPage = Math.min(startPage + maxPagesToShow - 1, totalPages);

    if (endPage - startPage < maxPagesToShow - 1) {
      startPage = Math.max(endPage - maxPagesToShow + 1, 1);
    }

    return Array.from(
      { length: endPage - startPage + 1 },
      (_, i) => startPage + i
    );
  };
  const handleVideoPlay = async (id) => {
    try {
      setVideoViews((prevViews) => {
        const currentViews = prevViews[id] || 0;
        return { ...prevViews, [id]: currentViews + 1 };
      });

      // Send updated views to the backend
      await axios.post(`/api/knowledgebase/${id}/view`, {
        knowledgeBaseId: id,
      });
      console.log(`Views updated for ID: ${id}`);
    } catch (error) {
      console.error("Error updating views:", error);
    }
  };

  // DB fetch for views and likes
  class KnowledgeBase extends Component {
    constructor(props) {
      super(props);
      this.state = {
        knowledgeBaseItems: [], // Existing data
        searchQuery: "",
        selectedCategory: "all",
        viewsCount: {}, // NEW: To store views for each item
        likesCount: {}, // NEW: To store likes for each item
      };
    }

    componentDidMount() {
      this.fetchKnowledgeBaseItems();
    }

    // Fetch Knowledge Base Items (Existing Function)
    fetchKnowledgeBaseItems = async () => {
      try {
        const response = await axios.get("/api/knowledgebase");
        this.setState(
          { knowledgeBaseItems: response.data },
          this.fetchStatsForItems
        );
      } catch (error) {
        console.error("Error fetching knowledge base items:", error);
      }
    };

    // NEW: Fetch Views and Likes for all Knowledge Base Items
    fetchStatsForItems = async () => {
      const { knowledgeBaseItems } = this.state;

      const viewsCount = {};
      const likesCount = {};

      try {
        for (let item of knowledgeBaseItems) {
          const statsResponse = await axios.get(
            `/api/stats/${item.knowledge_base_id}`
          );
          viewsCount[item.knowledge_base_id] = statsResponse.data.views || 0;
          likesCount[item.knowledge_base_id] = statsResponse.data.likes || 0;
        }
        this.setState({ viewsCount, likesCount });
      } catch (error) {
        console.error("Error fetching stats:", error);
      }
    };

    // NEW: Increment Views on Video Play
    incrementViews = async (knowledgeBaseId) => {
      try {
        await axios.post(`/api/stats/views/${knowledgeBaseId}`);
        this.setState((prevState) => ({
          viewsCount: {
            ...prevState.viewsCount,
            [knowledgeBaseId]: prevState.viewsCount[knowledgeBaseId] + 1,
          },
        }));
      } catch (error) {
        console.error("Error incrementing views:", error);
      }
    };
    // NEW: Increment Likes on Button Click
    incrementLikes = async (knowledgeBaseId) => {
      try {
        await axios.post(`/api/stats/likes/${knowledgeBaseId}`);
        this.setState((prevState) => ({
          likesCount: {
            ...prevState.likesCount,
            [knowledgeBaseId]: prevState.likesCount[knowledgeBaseId] + 1,
          },
        }));
      } catch (error) {
        console.error("Error incrementing likes:", error);
      }
    };

    // Handle Search Query (Existing Function)
    handleSearchChange = (e) => {
      this.setState({ searchQuery: e.target.value });
    };

    // Render Video Preview with Stats
    renderPreview = (item) => {
      const { viewsCount, likesCount } = this.state;

      return (
        <div key={item.knowledge_base_id} className="video-preview">
          <h3>{item.title}</h3>
          <video
            width="300"
            controls
            onPlay={() => this.incrementViews(item.knowledge_base_id)} // NEW: Increment views on play
          >
            <source src={item.video_url} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          <div className="stats">
            <p>Views: {viewsCount[item.knowledge_base_id] || 0}</p>
            <p>Likes: {likesCount[item.knowledge_base_id] || 0}</p>
            <button onClick={() => this.incrementLikes(item.knowledge_base_id)}>
              👍 Like
            </button>
          </div>
        </div>
      );
    };

    // Render the Full Knowledge Base List (Existing Function)
    renderKnowledgeBaseList = () => {
      const { knowledgeBaseItems, searchQuery, selectedCategory } = this.state;

      const filteredItems = knowledgeBaseItems.filter((item) => {
        const matchesSearch = item.title
          .toLowerCase()
          .includes(searchQuery.toLowerCase());
        const matchesCategory =
          selectedCategory === "all" || item.category === selectedCategory;

        return matchesSearch && matchesCategory;
      });

      return filteredItems.map((item) => this.renderPreview(item));
    };
  }
  const FileDropdown = ({ fileData, setFilteredData }) => {
    const [filetypeDropdownOpen, setFiletypeDropdownOpen] = useState(false);
    const [selectedFiletype, setSelectedFiletype] = useState("");

    // Trigger filtering logic when selectedFiletype changes
    useEffect(() => {
      if (selectedFiletype) {
        // Filter the data based on selectedFiletype
        const filtered = fileData.filter((file) =>
          file.media_files.some(
            (media) => media.content_type === selectedFiletype
          )
        );
        setFilteredData(filtered);
      } else {
        // If no filter selected, show all data
        setFilteredData(fileData);
      }
    }, [selectedFiletype, fileData, setFilteredData]);
  };

  return (
    <Container fluid className="mt-2">
      {/* Search and Filters in one row */}
      <Row className="align-items-center g-3 mb-3">
        <Col xs="12" sm="6" className="mb-1">
          <InputGroup className="d-flex align-items-center">
            <Input
              type="text"
              placeholder="Search files..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="w-100"
            />
          </InputGroup>
        </Col>
        <Col xs="12" sm="2" className="mb-2">
          <Dropdown
            isOpen={filetypeDropdownOpen}
            toggle={() => setFiletypeDropdownOpen(!filetypeDropdownOpen)}
          >
            <DropdownToggle caret className="w-100 text-truncate">
              {selectedFiletype || "File Type"}
            </DropdownToggle>
            <DropdownMenu style={{ maxHeight: "200px", overflowY: "auto" }}>
              <DropdownItem onClick={() => setSelectedFiletype("")}>
                All Filetypes
              </DropdownItem>
              {["Video", "Document", "PDF"].map((type, index) => (
                <DropdownItem
                  key={index}
                  onClick={() =>
                    setSelectedFiletype(type === "All Filetypes" ? "" : type)
                  }
                >
                  {type}
                </DropdownItem>
              ))}
            </DropdownMenu>
          </Dropdown>
        </Col>

        <Col xs="12" sm="2" className="mb-2">
 <Dropdown isOpen={locationDropdownOpen} toggle={toggleLocationDropdown}>
      <DropdownToggle caret className="w-100 text-truncate">
        {selectedLocation || "Location"}
      </DropdownToggle>
      <DropdownMenu style={{ maxHeight: "250px", overflowY: "auto" }}>
        <DropdownItem onClick={() => handleLocationSelect("")}>All Locations</DropdownItem>
        <DropdownItem onClick={() => handleLocationSelect("Kieweit - Temporary Boilers")}>
          Kieweit - Temporary Boilers
        </DropdownItem>
        {uniqueLocations.map((location, index) => (
          <DropdownItem key={index} onClick={() => handleLocationSelect(location)}>
            {location}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </Dropdown>
        </Col>

        <Col xs="12" sm="2" className="mb-2">
          <Dropdown
            isOpen={categoryDropdownOpen}
            toggle={toggleCategoryDropdown}
          >
            <DropdownToggle caret className="w-100 text-truncate">
              {selectedCategory || "Category"}
            </DropdownToggle>
            <DropdownMenu style={{ maxHeight: "250px", overflowY: "auto" }}>
              <DropdownItem onClick={() => setSelectedCategory("")}>
                All Categories
              </DropdownItem>
              {uniqueCategories.map((category, index) => (
                <DropdownItem
                  key={index}
                  onClick={() => setSelectedCategory(category)}
                >
                  {category}
                </DropdownItem>
              ))}
            </DropdownMenu>
          </Dropdown>
        </Col>
      </Row>

      {/* Cards */}

      <Row>
        {currentItems.length > 0 ? (
          currentItems.map((file) => (
            <Col
              xs="12"
              sm="6"
              lg="4"
              xl="4"
              key={file.knowledge_base_id}
              className="mb-4"
            >
              {/* Card with Larger Height, Rounded Edges */}
              <Card
                className="h-100 shadow rounded-3 position-relative"
                style={{ minHeight: "300px" }}
              >
                {/* Video Preview */}
                {renderPreview(file)}

                {/* Card Body */}
                <CardBody className="d-flex flex-column justify-content-between">
                  {/* Title */}
                  <CardTitle
                    tag="h6"
                    className="text-left mb-2"
                    style={{
                      fontSize: "0.9rem", // Adjust font size for the title
                      whiteSpace: "normal",
                      overflow: "hidden",
                      lineHeight: "1.2",
                    }}
                  >
                    {file.title}
                  </CardTitle>

                  {/* Description */}
                  <CardText
                    className="mb-3"
                    style={{
                      fontSize: "0.85rem", // Smaller font size for description
                      lineHeight: "1.3em",
                      marginTop: "18px", // Space between title and description
                      textAlign: "left", // Ensure description is left-aligned
                      display: "-webkit-box",
                      WebkitLineClamp: 2, // Limit to 2 lines
                      WebkitBoxOrient: "vertical",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {file.description}
                  </CardText>

                  {/* Buttons in Row */}
                  <div className="d-flex justify-content-between align-items-center mt-auto">
                    {/* View Details Button on the Left */}
                    <Button
                      color="primary"
                      size="sm"
                      onClick={() =>
                        window.open(
                          `/details/${file.knowledge_base_id}`,
                          "_blank"
                        )
                      }
                    >
                      View Details
                    </Button>

                    {/* Copy Link Icon on the Right */}
                    {/* <Button
                      color="link"
                      className="p-0"
                      onClick={() => copyDeepLink(file.knowledge_base_id)}
                      title="Copy Link"
                    >
                      <FiLink size={18} />
                    </Button> */}
<div className="text-end">
  <Button
    color="link"
    className="p-0"
    onClick={() => copyDeepLink(file.knowledge_base_id)}
    title="Copy Link"
  >
    <FiLink size={18} />
  </Button>
</div>



                    <div>

    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          ))
        ) : (
          <p className="text-center">Please wait Loading Data...</p>
        )}
      </Row>


      {/* Pagination - Bottom of the Page, Align Right */}
      {totalPages > 1 && (
        <Pagination
          style={{
            display: "flex",
            justifyContent: "right",
            marginTop: "-5px",
          }}
        >
          <PaginationItem disabled={currentPage === 1}>
            <PaginationLink first onClick={() => handlePageChange(1)} />
          </PaginationItem>
          <PaginationItem disabled={currentPage === 1}>
            <PaginationLink
              previous
              onClick={() => handlePageChange(currentPage - 1)}
            />
          </PaginationItem>
          {getPaginationRange().map((pageNumber) => (
            <PaginationItem
              active={currentPage === pageNumber}
              key={pageNumber}
            >
              <PaginationLink onClick={() => handlePageChange(pageNumber)}>
                {pageNumber}
              </PaginationLink>
            </PaginationItem>
          ))}
          <PaginationItem disabled={currentPage === totalPages}>
            <PaginationLink
              next
              onClick={() => handlePageChange(currentPage + 1)}
            />
          </PaginationItem>
          <PaginationItem disabled={currentPage === totalPages}>
            <PaginationLink last onClick={() => handlePageChange(totalPages)} />
          </PaginationItem>
        </Pagination>
      )}
      
    </Container>
    
  );
};

export default KnowledgebaseMain;

import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import AuthForm from "./components/Login/AuthForm";
import Home from "./pages/home/home";
import Dash from "./components/KB/Dash";
import KnowledgeBaseTable from "./components/ViewKB/KnowledgeBaseTable";
import KnowledgeBaseDetail from "./components/ViewKB/KnowledgeBaseDetail";
import EditKnowledgeBase from "./components/EditKB/EditKnowledgeBase";
import KnowledgebaseMain from "./components/KB";
import KnowledgeDetail from "./components/KB/KnowledgeDetail";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import S3Explorer from "./components/KB/S3Explorer"; // Import the S3 Explorer component


function App() {
  const [fileData, setFileData] = useState([]);

  useEffect(() => {
    axios.get("https://kb.telepresenz.com/api/knowledge_base").then((res) => {
      setFileData(res.data);
    });
  }, []);

  return (
    <Router>
      <Routes>
        <Route path="" element={<AuthForm />} />
        <Route path="/home" element={<Home />} />
        <Route path="/s3explorer" element={<S3Explorer />} />

        <Route
          path="/dashboard"
          element={<KnowledgebaseMain fileData={fileData} />}
        />
        <Route path="/EditKB" element={<EditKnowledgeBase />} />
        <Route path="/" element={<KnowledgeBaseTable />} />
        <Route path="/detailsgrid/:id" element={<KnowledgeBaseDetail />} />
        <Route
          path="/details/:id"
          element={<KnowledgeDetail fileData={fileData} />}
        />
      </Routes>
    </Router>
  );
}

export default App;

import React, { useState, useCallback, useEffect } from "react";
import axios from "axios";
import { Menu as MenuIcon } from "@mui/icons-material";

import {
  Box,
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Collapse,
  Divider,
} from "@mui/material";
import {
  ExpandLess,
  ExpandMore,
  Dashboard,
  ExitToApp,
  AccountCircle,
  DarkMode,
  WbSunny,
  Engineering,
  MenuBook,
  AddCircleOutline,
  Visibility,
  ChevronLeft,
  ChevronRight,
} from "@mui/icons-material";
import { useNavigate, useLocation } from "react-router-dom";
import AddKnowledgeBase from "../../components/KB/AddKnowledgeBase";
import KnowledgeBaseTable from "../../components/ViewKB/KnowledgeBaseTable";
import Dash from "../../components/KB/Dash";
import EditKnowledgeBase from "../../components/EditKB/EditKnowledgeBase";
import KnowledgebaseMain from "../../components/KB";
import { Edit } from "@mui/icons-material";

const Home = () => {
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [smartITMOpen, setSmartITMOpen] = useState(true);
  const [addKBOpen, setAddKBOpen] = useState(false);
  const [viewKB, setViewKBOpen] = useState(false);
  const [currentView, setCurrentView] = useState("dashboard");
  const [editKBOpen, setEditKBOpen] = useState(false);
  const [uniqueKey, setUniqueKey] = useState(0); // New state to force re-mount

  const [fileData, setFileData] = useState([]);

  useEffect(() => {
    axios.get("https://kb.telepresenz.com/api/knowledge_base").then((res) => {
      setFileData(res.data);
    });
  }, []);

  const navigate = useNavigate();
  const location = useLocation();
  const currentUser =
    location?.state?.currentUser || localStorage.getItem("user");

  const handleLogout = useCallback(() => {
    try {
      localStorage.removeItem("user");
      navigate("/");
    } catch (error) {
      console.error("Logout error:", error);
    }
  }, [navigate]);

  const toggleSidebar = () => {
    setSidebarOpen((prev) => !prev);
  };

  const handleViewChange = (view) => {
    setCurrentView(view);
    setUniqueKey((prevKey) => prevKey + 1); // Increment to force remount
  };

  const handleDashboardClick = () => handleViewChange("dashboard");
  const toggleAddKB = () => handleViewChange("addKB");
  const toggleViewKB = () => handleViewChange("viewKB");
  const toggleEditKB = () => handleViewChange("editKB");

  return (
    <Box
      sx={{
        display: "flex",
        minHeight: "100vh",
        backgroundColor: "#f5f5f5",
        color: "#000000",
        fontFamily: '"Roboto", sans-serif',
      }}
    >
      {/* Top Bar */}
      <AppBar
        position="fixed"
        sx={{
          zIndex: 1201,
          backgroundColor: "#1F2937", // New Top Bar Color
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)", // Soft shadow for depth
        }}
      >
        <Toolbar
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {/* Logo and Sidebar Toggle */}
          <Box sx={{ display: "flex", alignItems: "center" }}>
            {/* Logo */}
            <img
              src="/images/L120.png"
              alt="Logo"
              style={{ height: "40px", marginRight: "10px" }}
            />
            <IconButton onClick={toggleSidebar} sx={{ color: "#fff" }}>
              <MenuIcon />
            </IconButton>
          </Box>

          {/* Centered Title */}
          <Typography
            variant="h6"
            sx={{
              flexGrow: 1,
              textAlign: "center",
              fontWeight: "bold",
              fontSize: "1.4rem",
              color: "#fff",
            }}
          >
            Telepresenz Knowledge Bank
          </Typography>

          {/* User Info and Logout */}
          <Box sx={{ display: "flex", alignItems: "center" }}>
            {currentUser && (
              <Typography
                variant="body1"
                sx={{ color: "#fff", marginRight: 2 }}
              >
                Hello, {currentUser}!
              </Typography>
            )}
            <IconButton onClick={handleLogout} sx={{ color: "#fff" }}>
              <ExitToApp />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>

      {/* Sidebar */}
      <Drawer
        variant="permanent"
        sx={{
          width: sidebarOpen ? 250 : 80,
          flexShrink: 0,
          transition: "all 0.3s ease-in-out",
          "& .MuiDrawer-paper": {
            width: sidebarOpen ? 250 : 80,
            boxSizing: "border-box",
            backgroundColor: "#374151", // New Sidebar Color
            color: "#fff",
            borderRight: "1px solid #444",
            overflowX: "hidden",
            borderRadius: "0px 15px 15px 0px", // Rounded corners for sidebar
            boxShadow: "2px 0px 15px rgba(0, 0, 0, 0.3)", // Subtle shadow effect
            display: "flex",
            flexDirection: "column",
            height: "100vh",
            justifyContent: "space-between", // Ensures footer is pushed to the bottom
          },
        }}
      >
        <Box sx={{ flexGrow: 1 }}>
          <Toolbar />
          <List>
            <ListItem button onClick={handleDashboardClick}>
              <ListItemIcon>
                <Dashboard style={{ color: "#fff", fontSize: "1.5rem" }} />
              </ListItemIcon>
              {sidebarOpen && <ListItemText primary="Dashboard" />}
            </ListItem>

            <ListItem button onClick={() => setSmartITMOpen(!smartITMOpen)}>
              <ListItemIcon>
                <Engineering style={{ color: "#fff", fontSize: "1.5rem" }} />
              </ListItemIcon>
              {sidebarOpen && <ListItemText primary="Knowledge Base" />}
              {smartITMOpen ? <ExpandLess /> : <ExpandMore />}
            </ListItem>

            <Collapse in={smartITMOpen} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem
                  button
                  sx={{
                    pl: 4,
                    "&:hover": {
                      backgroundColor: "#3B7A57", // Hover color for items
                    },
                  }}
                  onClick={toggleAddKB}
                >
                  <ListItemIcon>
                    <AddCircleOutline style={{ color: "#fff" }} />
                  </ListItemIcon>
                  {sidebarOpen && <ListItemText primary="Add KB" />}
                </ListItem>

                <ListItem
                  button
                  sx={{
                    pl: 4,
                    "&:hover": {
                      backgroundColor: "#3B7A57", // Hover color for items
                    },
                  }}
                  onClick={toggleViewKB}
                >
                  <ListItemIcon>
                    <Visibility style={{ color: "#fff" }} />
                  </ListItemIcon>
                  {sidebarOpen && <ListItemText primary="View KB" />}
                </ListItem>

                <ListItem
                  button
                  sx={{
                    pl: 4,
                    "&:hover": {
                      backgroundColor: "#3B7A57", // Hover color for items
                    },
                  }}
                  onClick={toggleEditKB}
                >
                  <ListItemIcon>
                    <Edit style={{ color: "#fff" }} />
                  </ListItemIcon>
                  {sidebarOpen && <ListItemText primary="Edit KB" />}
                </ListItem>
              </List>
            </Collapse>
          </List>
        </Box>

        {/* Footer */}
        <Box
          sx={{
            padding: "10px 0",
            textAlign: "center",
            backgroundColor: "#374151",
            color: "#fff",
          }}
        >
          <Typography variant="body2" color="inherit">
            Copyright Telepresenz &copy;
          </Typography>
          <Typography variant="body2" color="inherit">
            Version 01.00.00
          </Typography>
        </Box>
      </Drawer>

      {/* Main Content */}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          bgcolor: "#ffffff",
          padding: 3,
          paddingTop: 9,
        }}
      >
        {/* This key change forces the component to reload on each view */}
        {currentView === "dashboard" && (
          <KnowledgebaseMain
            key={`dashboard-${uniqueKey}`}
            fileData={fileData}
          />
        )}
        {currentView === "addKB" && (
          <AddKnowledgeBase key={`addKB-${uniqueKey}`} />
        )}
        {currentView === "viewKB" && (
          <KnowledgeBaseTable key={`viewKB-${uniqueKey}`} />
        )}
        {currentView === "editKB" && (
          <EditKnowledgeBase key={`editKB-${uniqueKey}`} />
        )}
      </Box>
    </Box>
  );
};

export default Home;

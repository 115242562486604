import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Typography,
  Grid,
  CircularProgress,
  Alert,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  TableContainer,
  Paper,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  TextField,
  Pagination,
  Tabs,
  Tab,
  Button,
  InputAdornment,
} from "@mui/material";
import { Form, Row, Col, Container, Toast } from "react-bootstrap";
import AWS from "aws-sdk";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "react-bootstrap"; // Import Modal from react-bootstrap
import "bootstrap-icons/font/bootstrap-icons.css";
import "bootstrap/dist/css/bootstrap.min.css";

const EditKnowledgeBase = () => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [categoryFilter, setCategoryFilter] = useState("");
  const [locationFilter, setLocationFilter] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sortDirection, setSortDirection] = useState("asc");
  const [sortColumn, setSortColumn] = useState("title");
  const [selectedItem, setSelectedItem] = useState(null);
  const [editedData, setEditedData] = useState(null);
  const [activeTab, setActiveTab] = useState(0);
  const [mediaFiles, setMediaFiles] = useState([]); // New state for media files
  const [fileUpload, setFileUpload] = useState(null); // File upload state
  const [folderName, setFolderName] = useState();

  const [knowledgeBaseId, setKnowledgeBaseId] = useState(1);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [status, setStatus] = useState("Edited");
  const [category, setCategory] = useState("");
  const [location, setLocation] = useState("");
  const [selectedContentType, setSelectedContentType] = useState("");
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [transcriptionFile, setTranscriptionFile] = useState(null);
  const [tagsInput, setTagsInput] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState("");
  const [showLoadingPopup, setShowLoadingPopup] = useState(false);
  const [files, setFiles] = useState([]);
  const fileInputRef = useRef(null); // Create a ref
  const [editingId, setEditingId] = useState(null); // ID of the record being edited
  const [knowledgeBaseList, setKnowledgeBaseList] = useState([]); // State to store Knowledge Base IDs and Titles
  const [selectedRecord, setSelectedRecord] = useState(null); // Selected record details
  const [editingIndex, setEditingIndex] = useState(null);
  const [searchFilter, setSearchFilter] = useState("");
  const [showImage, setShowImage] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const [isExpanded, setIsExpanded] = useState(true);
  const [modal, setModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [tagsInputs, setTagsInputs] = useState([]); // Array to hold unique tag inputs for each file

  let postData;
  const toggleModal = () => {
    setModal(!modal);
    console.log(modal);
  };

  // AWS S3 configuration
  AWS.config.update({
    accessKeyId: "AKIAQFC27JCSYTI2UK5J",
    secretAccessKey: "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",
    region: "us-east-1",
  });

  const s3 = new AWS.S3();
  useEffect(() => {
    // Fetch knowledge base list
    const fetchKBList = async () => {
      const response = await fetch("https://kb.telepresenz.com/api/knowledge_base");
      const data = await response.json();
      setKnowledgeBaseList(data);
    };
    fetchKBList();
  }, []);

  useEffect(() => {
    const fetchKnowledgeBaseList = async () => {
      try {
        const response = await fetch(
          "https://kb.telepresenz.com/api/knowledge_base"
        );
        if (response.ok) {
          const data = await response.json();
          setKnowledgeBaseList(data); // Store fetched records
        } else {
          console.error("Failed to fetch Knowledge Base list");
        }
      } catch (error) {
        console.error("Error fetching Knowledge Base list:", error);
      }
    };

    fetchKnowledgeBaseList();
  }, []);

  const categories = ["SOPs", "Workflows", "Incidents", "Manuals", "Knowledge"];
  const locations = [
    "DTP AV Williams",
    "DTP Bio Science",
    "CEP",
    "DTP4",
    "B046 - DTP Marie Mount ",
    "B224 - DTP Atlantic",
    "B250 - DTP Lennart Town",
    "B068 - DTP Eppley Rec Center",
    "B425 - DTP Prince Frederick",
    "B073- DTP HJ Patterson",
    "B226 - DTP Edward Saint John",
    "B392 - DTP 3",
    "B607 - DTP 2",
    "B419 - DTP Oakland",
    "B141 - DTP Tawes Hall",
    "B416 - DTP 5",
    "B143 - DTP Benjamin Hall",
    "B019 - DTP 1",
    "General",
  ];

  const fetchData = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await fetch("https://kb.telepresenz.com/api/knowledge_base");
      if (!response.ok) {
        throw new Error("Failed to fetch data. Please try again later.");
      }
      const result = await response.json();
      setData(result);
      setFilteredData(result);
    } catch (error) {
      setError(`Error: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleEdit = async (id) => {
    // Check if the id is valid
    console.log(id);
    if (!id) {
      setError("Invalid ID");
      return; // Prevent the API call if ID is invalid
    }

    setLoading(true);
    setError(null);

    try {
      // Debugging: Log the ID to check if it is being passed correctly
      console.log("Editing record with ID:", id);

      const response = await fetch(
        `https://kb.telepresenz.com/api/knowledge_base/${id}`
      );

      if (!response.ok) {
        throw new Error("Failed to fetch record details.");
      }

      const result = await response.json();

      // Debugging: Log the fetched result to ensure correct data is returned
      console.log("Fetched result:", result);

      if (!result || !result.knowledge_base_id) {
        setError("Record not found.");
        return; // If no result is found, display an error message
      }

      setEditedData(result);
      setSelectedItem(result);
      setMediaFiles(result?.media_files || []); // Load existing media files
      setActiveTab(1); // Switch to the Edit tab
      console.log("mediaFiles", mediaFiles);
    } catch (error) {
      setError(`Error: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  const handleChangeEditData = (e) => {
    setEditedData({
      ...editedData,
      [e.target.name]: e.target.value,
    });
  };

  const handleMediaUpload = (e) => {
    const files = Array.from(e.target.files);
    setFileUpload(files);
  };

  const handleDeleteMedia = (mediaIndex) => {
    const newMedia = mediaFiles.filter((_, index) => index !== mediaIndex);
    setMediaFiles(newMedia);
  };

  const handleSave = async () => {
    setLoading(true);
    setError(null);
    try {
      // Upload media files to AWS
      const uploadedFiles = await uploadMediaToAWS(fileUpload);
      const updatedMedia = [...mediaFiles, ...uploadedFiles]; // Combine existing and new media

      // Update editedData with new media URLs
      const updatedRecord = { ...editedData, media: updatedMedia };

      // Save updated data to PostgreSQL
      const response = await fetch(
        `https://kb.telepresenz.com/api/knowledge_base/${editedData.id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(updatedRecord),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to save the changes.");
      }
      const result = await response.json();
      setData((prevData) =>
        prevData.map((item) => (item.id === result.id ? result : item))
      );
      setActiveTab(0); // Switch back to the table view
    } catch (error) {
      setError(`Error: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  const uploadMediaToAWS = async (files) => {
    const uploadedFiles = [];
    for (let file of files) {
      // Perform AWS S3 upload logic here
      // For example, use AWS SDK to upload the file and get the URL
      const fileUrl = await uploadToAWS(file);
      uploadedFiles.push({ filename: file.name, url: fileUrl });
    }
    return uploadedFiles;
  };

  useEffect(() => {
    setCategory(editedData?.category);
    setLocation(editedData?.location);
  }, [editedData]);

  const uploadToAWS = async (file) => {
    // Replace with your actual AWS upload logic
    return `https://kbase-repo.s3.amazonaws.com/dataupload/KnowledgeBase/${file.folderName}/${file.name}`;
  };

  const handleContentTypeChange = (event) => {
    setSelectedContentType(event.target.value);
    setSelectedFiles([]);
    setTranscriptionFile(null);
  };

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    setSelectedFiles(files);
    setFiles(event.target.files);
  };

  const handleTranscriptionFileChange = (event) => {
    setTranscriptionFile(event.target.files[0]);
  };

  const handleAddFiles = () => {
    const updatedFiles = selectedFiles.map((file) => ({
      file,
      contentType: selectedContentType,
      transcriptionFile:
        selectedContentType === "Video" ? transcriptionFile : null,
      description: "",
      tags: [],
      size: file.size,
    }));
    setMediaFiles((prevFiles) => [...prevFiles, ...updatedFiles]);
    setSelectedFiles([]);
    setTranscriptionFile(null);
  };
  console.log("media files", mediaFiles);

  const handleSaveFileEdit = (updatedFile) => {
    const updatedFiles = [...mediaFiles];
    updatedFiles[editingIndex] = updatedFile;
    setMediaFiles(updatedFiles);
    setEditingIndex(null);
  };

  // const handleSubmit = async (event) => {
  //   event.preventDefault();
  //   setLoadingMessage("Uploading files to server...");
  //   setShowLoadingPopup(true); // Show the loading popup
  //   // setLoadingMessage('Uploading files to server...');
  //   // Get the current date and time in YYYY-MM-DD_HH-MM-SS format
  //   const dateTimeStamp = new Date()
  //     .toISOString()
  //     .replace(/[-:T]/g, "")
  //     .split(".")[0];

  //   //const folderName = `KB-${knowledgeBaseId}-${location}-${title}-${dateTimeStamp}`;
  //   try {
  //     const response = await fetch(
  //       `http://localhost:5001/api/knowledge_base/${editedData.id}`
  //     ); // Fetch the record by ID
  //     const data = await response.json();

  //     // console.log("Extracted Folder Name:", folderName);
  //     if (data) {
  //       setFolderName(data.foldername); // Set the folder name
  //       setError(""); // Reset error if successful
  //     } else {
  //       throw new Error("No data found.");
  //     }
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //     setError("Error fetching data.");
  //     setFolderName(""); // Reset folderName in case of error
  //   }

  //   const uploadPromises = mediaFiles.map(async (item) => {
  //     const fileBlob =
  //       item.file instanceof Blob ? item.file : new Blob([item.file]);

  //     const fileKey = `${folderName}/${item.file?.name}`;
  //     const params = {
  //       Bucket: "kbase-repo/dataupload/KnowledgeBase",
  //       Key: fileKey,
  //       Body: fileBlob,
  //     };
  //     console.log("Uploading to S3:", params);
  //     await s3.upload(params).promise();

  //     // Clear the file input control
  //     if (fileInputRef.current) {
  //       fileInputRef.current.value = ""; // This clears the file input
  //     }

  //     let transcriptionFileKey = null;

  //     if (item.transcriptionFile) {
  //       const transcriptionFileBlob =
  //         item.transcriptionFile instanceof Blob
  //           ? item.transcriptionFile
  //           : new Blob([item.transcriptionFile]);

  //       transcriptionFileKey = `${folderName}/${item.transcriptionFile.name}`;
  //       const transcriptionParams = {
  //         Bucket: "kbase-repo/dataupload/KnowledgeBase",
  //         Key: transcriptionFileKey,
  //         Body: transcriptionFileBlob,
  //       };
  //       console.log("Uploading transcription to S3:", transcriptionParams);
  //       await s3.upload(transcriptionParams).promise();
  //     }
  //     // Generate the complete URL for each uploaded file
  //     const fileUrl = `https://kbase-repo.s3.amazonaws.com/dataupload/KnowledgeBase/${folderName}${fileKey}`;
  //     const transcriptionFileUrl = transcriptionFileKey
  //       ? `https://kbase-repo.s3.amazonaws.com/dataupload/${transcriptionFileKey}`
  //       : null;

  //     return {
  //       ...item,
  //       filePath: fileKey,
  //       transcriptionFilePath: transcriptionFileKey,
  //       fileUrl: fileUrl, // Adding the AWS URL for the file
  //       transcriptionFileUrl: transcriptionFileUrl, // Adding the AWS URL for the transcription file
  //     };
  //   });

  //   const uploadedFiles = await Promise.all(uploadPromises);
  //   console.log("Uploaded Files:", uploadedFiles);
  //   setIsSubmitted(true);

  //   // Update loading message after files are uploaded
  //   setLoadingMessage("Files uploaded. Updating data to database...");
  //   // Update the data in the database

  //   // Prepare data for API
  //   const postData = {
  //     title,
  //     description,
  //     status,
  //     category,
  //     location,
  //     folderName,
  //     mediaFiles: uploadedFiles.map((file) => ({
  //       filePath: file.fileUrl,
  //       contentType: file.contentType,
  //       description: file.description,
  //       tags: file.tags,
  //       size: file.size,
  //       transcriptionFilePath: file.transcriptionFileUrl || null,
  //     })),
  //   };

  //   try {
  //     console.log("Sending request to API...");
  //     // const response = await fetch('http://localhost:5001/api/saveKnowledgeBaseData', {
  //     const endpoint = editingId
  //       ? `http://localhost:5001/api/knowledge_base/${editingId}` // Update endpoint
  //       : "http://localhost:5001/api/saveKnowledgeBaseData"; // Create endpoint

  //     const response = await fetch(endpoint, {
  //       method: editingId ? "PUT" : "POST", // Use PUT for updates
  //       headers: { "Content-Type": "application/json" },
  //       body: JSON.stringify({
  //         title,
  //         description,
  //         status,
  //         category,
  //         location,
  //         mediaFiles: uploadedFiles,
  //       }),
  //     });

  //     console.log("API response:", response);
  //     if (response.ok) {
  //       const result = await response.json();
  //       console.log("Response from API:", result);
  //       setShowToast(true);
  //       setIsSubmitted(true);
  //       setShowLoadingPopup(false); // Hide the loading popup after successful data save

  //       // Reset all fields after successful submission and toast message
  //       setKnowledgeBaseId(1);
  //       setTitle("");
  //       setDescription("");
  //       setStatus("Created");
  //       setCategory("");
  //       setLocation("");
  //       setMediaFiles([]);
  //       setFiles([]); // Reset state if needed
  //       setSelectedContentType("");
  //       setSelectedFiles([]);
  //       setTranscriptionFile(null);
  //       setTagsInput("");

  //       setFiles([]); // Clear selected files state
  //       setSelectedFiles([]); // Clear selected files state
  //       setTranscriptionFile(null); // Clear transcription file
  //       setTagsInput(""); // Clear tags input
  //       setTitle(""); // Clear title
  //       setDescription(""); // Clear description
  //       // Ensure file input is cleared after submission
  //       if (fileInputRef.current) {
  //         fileInputRef.current.value = ""; // Clear file input
  //       }
  //     } else {
  //       console.error("API response error:", response.statusText);
  //       alert("Failed to save data.");
  //       setShowLoadingPopup(false); // Hide the loading popup after successful data save
  //     }
  //   } catch (error) {
  //     // console.error('Error during fetch call:', error);
  //     alert("An error occurred. Please try again.");
  //     setShowLoadingPopup(false); // Hide the loading popup after successful data save
  //   }
  // };

  const handleAddNewRecord = () => {
    setKnowledgeBaseId(knowledgeBaseId + 1);
    setTitle("");
    setDescription("");
    setStatus("Created");
    setCategory("");
    setLocation("");
    setMediaFiles([]);
    setSelectedContentType("");
    setSelectedFiles([]);
    setTranscriptionFile(null);
    setIsSubmitted(false);
    setShowToast(false);
    // Clear file input
    if (fileInputRef.current) {
      fileInputRef.current.value = ""; // This clears the file input
    }
  };

  // Handle editing the file - For now, just logging the file being edited
  const handleEditFile = (index) => {
    console.log("Editing file:", mediaFiles[index]?.file?.name);
    // You can modify this to open a modal or navigate to an edit page.
    // Example: setEditFile(mediaFiles[index]);
  };

  // Handle deleting the file
  const handleDeleteFile = (index) => {
    const updatedFiles = mediaFiles.filter((_, i) => i !== index);
    setMediaFiles(updatedFiles); // Assuming you are using a setter function to update state
  };

  // Handle changing description of the file
  const handleFileDescriptionChange = (index, event) => {
    const updatedFiles = [...mediaFiles];
    updatedFiles[index].media_description = event.target.value; // or updatedFiles[index].description
    setMediaFiles(updatedFiles); // Update state with the new description
  };

  // Handle saving the description (You can extend this to save to an API)
  const handleSaveDescription = (index) => {
    console.log("Description saved for file:", mediaFiles[index].file.name);
  };

  // Handle adding tags
  // const handleAddTags = (index) => {
  //   if (tagsInput.trim()) {
  //     const updatedFiles = [...mediaFiles];
  //     updatedFiles[index].tags.push(tagsInput.trim());
  //     setMediaFiles(updatedFiles);
  //     setTagsInput(""); // Clear the input field
  //   }
  // };

  // Handle removing a tag
  const handleRemoveTag = (index, tagIndex) => {
    const updatedFiles = [...mediaFiles];
    updatedFiles[index].tags.splice(tagIndex, 1);
    setMediaFiles(updatedFiles);
  };

  // Handle changes in the tag input field
  // const handleTagsInputChange = (event) => {
  //   setTagsInput(event.target.value); // Update the tags input field
  // };

  // Handle Search, Location, Category Filters
  const handleSearchChange = (e) => {
    setSearchFilter(e.target.value);
    filterData(e.target.value, locationFilter, categoryFilter);
  };

  const handleLocationChange = (e) => {
    setLocationFilter(e.target.value);
    filterData(searchFilter, e.target.value, categoryFilter);
  };

  const handleCategoryChange = (e) => {
    setCategoryFilter(e.target.value);
    filterData(searchFilter, locationFilter, e.target.value);
  };

  const filterData = (search, location, category) => {
    const filtered = data.filter((item) => {
      return (
        (item.title.toLowerCase().includes(search.toLowerCase()) ||
          item.category.toLowerCase().includes(search.toLowerCase()) ||
          item.location.toLowerCase().includes(search.toLowerCase())) &&
        (location ? item.location === location : true) &&
        (category ? item.category === category : true)
      );
    });
    setFilteredData(filtered);
  };

  const highlightText = (text, search) => {
    if (!search) return text;
    const parts = text.split(new RegExp(`(${search})`, "gi"));
    return parts.map((part, index) =>
      part.toLowerCase() === search.toLowerCase() ? (
        <span key={index} style={{ backgroundColor: "yellow" }}>
          {part}
        </span>
      ) : (
        part
      )
    );
  };

  // Define the function to close the image modal
  const handleCloseImageModal = () => {
    setShowImage(false);
    setImageUrl("");
  };
  const getFileNameFromUrl = (url) => {
    // Match the filename from the URL (everything after the last `/`)
    const match = url ? url.split("/").pop() : null;
    return match ? decodeURIComponent(match) : "";
  };

  const handleViewImage = (filePath, fileType) => {
    console.log("File Path:", filePath); // Ensure this is a valid URL or file path
    console.log("File Type:", fileType); // Ensure this is the correct MIME type

    // Normalize image file types (image/jpg to image/jpeg)
    if (fileType === "image/jpg") {
      fileType = "image/jpeg";
    }

    // List of supported file types for direct viewing in the browser
    const viewableTypes = [
      "image/png",
      "image/jpeg",
      "image/jpg",
      "image/tiff",
      "image/PNG",
      "application/pdf",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document", // DOCX
      "application/vnd.ms-powerpoint", // PPT
      "application/vnd.openxmlformats-officedocument.presentationml.presentation", // PPTX
      "application/vnd.ms-excel", // XLS
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // XLSX
    ];

    // Check if file type is in the viewable types list
    if (viewableTypes.includes(fileType)) {
      const popupWindow = window.open(
        "",
        "popup",
        "width=800,height=600,scrollbars=yes"
      );

      // If the file is an image or PDF, show it in the popup
      if (fileType.startsWith("image") || fileType === "application/pdf") {
        const fileElement = document.createElement("img");
        fileElement.src = filePath;
        fileElement.style.width = "100%";
        fileElement.style.height = "auto";
        popupWindow.document.body.appendChild(fileElement); // Append the image to the popup

        if (fileType === "application/pdf") {
          // If it's a PDF, use an embedded PDF viewer
          const iframe = document.createElement("iframe");
          iframe.src = filePath;
          iframe.style.width = "100%";
          iframe.style.height = "100%";
          popupWindow.document.body.appendChild(iframe);
        }
      }
      // For Word, PPT, or Excel files, open in Google Docs viewer
      else if (fileType.includes("application/vnd.openxmlformats")) {
        const viewerUrl = `https://docs.google.com/viewer?url=${encodeURIComponent(
          filePath
        )}`;
        popupWindow.location.href = viewerUrl;
      }
      // For MS Word (legacy format)
      else if (fileType === "application/msword") {
        const viewerUrl = `https://docs.google.com/viewer?url=${encodeURIComponent(
          filePath
        )}`;
        popupWindow.location.href = viewerUrl;
      }
      // For PPT or PPTX
      else if (
        fileType.includes("application/vnd.ms-powerpoint") ||
        fileType.includes("presentationml")
      ) {
        const viewerUrl = `https://docs.google.com/viewer?url=${encodeURIComponent(
          filePath
        )}`;
        popupWindow.location.href = viewerUrl;
      }
      // For Excel or XLSX
      else if (
        fileType.includes("application/vnd.ms-excel") ||
        fileType.includes("spreadsheetml")
      ) {
        const viewerUrl = `https://docs.google.com/viewer?url=${encodeURIComponent(
          filePath
        )}`;
        popupWindow.location.href = viewerUrl;
      }
    } else {
      // If file type is not supported for viewing
      alert("This file type cannot be viewed in the popup.");
    }
  };

  // Function to toggle expand/collapse
  const toggleExpand = () => {
    setIsExpanded((prev) => !prev);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoadingMessage("Uploading files to server...");
    setShowLoadingPopup(true); // Show the loading popup
    // setLoadingMessage('Uploading files to server...');

    try {
      const response = await fetch(
        `http://localhost:5001/api/knowledge_base/${editedData.knowledge_base_id}`
      );

      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const data = await response.json();
      let fileUrl;

      // const folderName = data.foldername;
      const uploadPromises = mediaFiles.map(async (item) => {
        console.log("filename", item.file_path);
        const fileBlob =
          item.file instanceof Blob ? item.file : new Blob([item.file]);

        const foldername = data?.foldername;
        const fileKey = `${item?.file_path}`;
        setFolderName(foldername);

        console.log("foldername from state value", folderName);
        let newKey;

        if (item?.file?.name != undefined) {
          newKey = `${foldername}/${item?.file?.name} `;

          console.log("filekey", newKey);

          console.log(item?.file?.name ? newKey : fileKey);

          const params = {
            Bucket: "kbase-repo/dataupload/KnowledgeBase",
            Key: newKey,
            Body: fileBlob,
          };
          console.log("Uploading to S3:", params);
          fileUrl = `https://kbase-repo.s3.amazonaws.com/dataupload/KnowledgeBase/${newKey}`;
          await s3.upload(params).promise();
        } else {
          fileUrl = `${fileKey}`;
        }

        // Clear the file input control
        if (fileInputRef.current) {
          fileInputRef.current.value = ""; // This clears the file input
        }

        let transcriptionFileKey = null;

        if (item.transcriptionFile) {
          const transcriptionFileBlob =
            item.transcriptionFile instanceof Blob
              ? item.transcriptionFile
              : new Blob([item.transcriptionFile]);

          transcriptionFileKey = `${folderName}/${item.transcriptionFile.name}`;
          const transcriptionParams = {
            Bucket: "kbase-repo/dataupload/KnowledgeBase",
            Key: transcriptionFileKey,
            Body: transcriptionFileBlob,
          };
          console.log("Uploading transcription to S3:", transcriptionParams);
          await s3.upload(transcriptionParams).promise();
        }
        // Generate the complete URL for each uploaded file

        const transcriptionFileUrl = transcriptionFileKey
          ? `https://kbase-repo.s3.amazonaws.com/dataupload/KnowledgeBase/${transcriptionFileKey}`
          : null;

        // return {
        //   ...item,
        //   filePath: fileKey,
        //   transcriptionFilePath: transcriptionFileKey,
        // };
        return {
          ...item,
          file_path: fileUrl,
          transcriptionFilePath: transcriptionFileKey,
          fileUrl: fileUrl, // Adding the AWS URL for the file
          transcriptionFileUrl: transcriptionFileUrl, // Adding the AWS URL for the transcription file
        };
      });

      const uploadedFiles = await Promise.all(uploadPromises);

      console.log("fileupload__801", fileUrl);

      console.log("editedDAta__data", editedData);

      // Prepare data for API
      postData = {
        id: editedData.knowledge_base_id,
        title: editedData.title,
        description: editedData.description,
        status: status,
        category: category,
        location: location,
        folderName: data.foldername,
        mediaFiles: uploadedFiles.map((file) => ({
          media_id: file.media_id,
          filePath: file.file_path || fileUrl,
          contentType: file.content_type || file.contentType,
          description: file.media_description,
          tags: file.tags,
          size: file.size,
          transcriptionFilePath: file.transcription_file || null,
        })),
      };

      console.log("postData__819", postData);
      setError(""); // Reset error if successful
    } catch (error) {
      console.error("Error fetching data:", error);
      setError("Error fetching data.");
      setFolderName(""); // Reset folderName in case of error
    }

    // Update loading message after files are uploaded
    setLoadingMessage("Files uploaded. Updating data to database...");
    // Update the data in the database

    try {
      console.log("Sending request to API...", postData);
      const response = await fetch(
        "https://kb.telepresenz.com/api/updateKnowledgeBaseData",
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(postData),
        }
      );
      console.log("API response:", response);
      if (response.ok) {
        const result = await response.json();
        console.log("Response from API:", result);
        setShowToast(true);
        setIsSubmitted(true);
        setShowLoadingPopup(false); // Hide the loading popup after successful data save
        console.log("Folder Name:", folderName);
        console.log("Post Data:", postData);

        // Reset all fields after successful submission and toast message
        setKnowledgeBaseId(1);
        setTitle("");
        setDescription("");
        setStatus("Created");
        setCategory("");
        setLocation("");
        setMediaFiles([]);
        setFiles([]); // Reset state if needed
        setSelectedContentType("");
        setSelectedFiles([]);
        setTranscriptionFile(null);
        setTagsInput("");

        setFiles([]); // Clear selected files state
        setSelectedFiles([]); // Clear selected files state
        setTranscriptionFile(null); // Clear transcription file
        setTagsInput(""); // Clear tags input
        setTitle(""); // Clear title
        setDescription(""); // Clear description
        // Ensure file input is cleared after submission
        if (fileInputRef.current) {
          fileInputRef.current.value = ""; // Clear file input
        }
      } else {
        console.error("API response error:", response.statusText);
        alert("Failed to save data.");
        setShowLoadingPopup(false); // Hide the loading popup after successful data save
      }
    } catch (error) {
      // console.error('Error during fetch call:', error);
      alert("An error occurred. Please try again.");
      setShowLoadingPopup(false); // Hide the loading popup after successful data save
    }
  };

  //handle tags

  useEffect(() => {
    setTagsInputs(mediaFiles.map(() => "")); // Initialize empty inputs
  }, [mediaFiles]);

  const handleTagsInputChange = (index, e) => {
    const updatedInputs = [...tagsInputs];
    updatedInputs[index] = e.target.value;
    setTagsInputs(updatedInputs);
  };

  const handleAddTags = (index) => {
    const updatedFiles = [...mediaFiles];
    if (tagsInputs[index]) {
      updatedFiles[index].tags.push(tagsInputs[index]);
      setMediaFiles(updatedFiles);

      // Clear only the input for the specific file
      const updatedInputs = [...tagsInputs];
      updatedInputs[index] = "";
      setTagsInputs(updatedInputs);
    }
  };

  // Handle file upload

  return (
    <Box sx={{ padding: 3 }}>
      <Typography variant="h4" gutterBottom>
        Knowledge Base Dashboard
      </Typography>

      {error && <Alert severity="error">{error}</Alert>}
      {loading && (
        <Box sx={{ display: "flex", justifyContent: "center", marginTop: 2 }}>
          <CircularProgress />
        </Box>
      )}

      {!loading && !error && (
        <>
          {/* Tab Navigation */}
          <Tabs
            value={activeTab}
            onChange={(e, newTab) => setActiveTab(newTab)}
            orientation="horizontal" // This is the default, so no need to explicitly set it
            sx={{
              borderBottom: 1,
              borderColor: "divider",
              minWidth: 200, // Set the width of the tabs if needed
            }}
          >
            <Tab label="Table View" />
            <Tab label="Edit View" />
          </Tabs>

          {/* Edit Tab */}
          {activeTab === 1 && selectedItem && (
            <Container>
              <div className="d-flex justify-content-between align-items-center mb-0">
                <h3>Edit Knowledge Base Record</h3>
              </div>
              <Form>
                <fieldset disabled={isSubmitted}>
                  <div className="border rounded p-4 shadow-lg bg-light mb-4">
                    <Row className="mb-4">
                      <Col sm={3}>
                        <Form.Label>Knowledge Base ID</Form.Label>
                      </Col>
                      <Col sm={9}>
                        <Form.Control
                          type="text"
                          value={editedData?.knowledge_base_id}
                          readOnly
                        />
                      </Col>
                    </Row>

                    <Row className="mb-4">
                      <Col sm={3}>
                        <Form.Label>Status</Form.Label>
                      </Col>
                      <Col sm={9}>
                        <Form.Control
                          as="select"
                          value={status}
                          onChange={(e) => setStatus(e.target.value)}
                          className="shadow-sm"
                        >
                          <option>Updated</option>
                        </Form.Control>
                      </Col>
                    </Row>

                    <Row className="mb-4">
                      <Col sm={3}>
                        <Form.Label>Title</Form.Label>
                      </Col>
                      <Col sm={9}>
                        <Form.Control
                          as="textarea"
                          rows={3}
                          value={editedData?.title}
                          onChange={(e) => setTitle(e.target.value)}
                          className="shadow-sm"
                        />
                      </Col>
                    </Row>

                    <Row className="mb-4">
                      <Col sm={3}>
                        <Form.Label>Description</Form.Label>
                      </Col>
                      <Col sm={9}>
                        <Form.Control
                          as="textarea"
                          rows={5}
                          value={editedData?.description}
                          onChange={(e) => setDescription(e.target.value)}
                          className="shadow-sm"
                        />
                      </Col>
                    </Row>
                  </div>

                  <div className="border rounded p-4 shadow-lg bg-light mb-4">
                    <h5 className="font-weight-bold text-secondary mb-4">
                      Content Type & Categorization
                    </h5>

                    <Row className="mb-4">
                      <Col sm={3}>
                        <Form.Label>Category</Form.Label>
                      </Col>
                      <Col sm={9}>
                        <Form.Control
                          as="select"
                          value={category}
                          onChange={(e) => setCategory(e.target.value)}
                          className="shadow-sm"
                        >
                          <option>Select Category</option>
                          <option>SOPs</option>
                          <option>Workflows</option>
                          <option>Incidents</option>
                          <option>Manuals</option>
                          <option>Knowledge</option>
                        </Form.Control>
                      </Col>
                    </Row>

                    <Row className="mb-4">
                      <Col sm={3}>
                        <Form.Label>Location</Form.Label>
                      </Col>
                      <Col sm={9}>
                        <Form.Control
                          as="select"
                          value={location}
                          onChange={(e) => setLocation(e.target.value)}
                          className="shadow-sm"
                        >
                          <option>Select Location</option>
                          <option>B115 - DTP - AV Williams</option>
                          <option>B001 - CEP</option>
                          <option>B046 - DTP Marie Mount</option>
                          <option>B224 - DTP Atlantic</option>
                          <option>B250 - DTP - Leonardtown</option>
                          <option>B068 - DTP Eppley</option>
                          <option>B435 - Prince Frederick</option>
                          <option>B073 - DTP HJ Patterson</option>
                          <option>B226 - DTP - Edward St. John</option>
                          <option>B392 - DTP 3</option>
                          <option>B067 - DTP 2</option>
                          <option>B419 - DTP Oakland</option>
                          <option>B416 - DTP - Tawes Hall</option>
                          <option>B416 - DTP 5</option>
                          <option>B405 - DTP - Benjamin</option>
                          <option>B019 - DTP 1</option>
                          <option>B405 - DTP4</option>
                          <option>B144 - DTP - Bio Psych</option>
                          <option>B400 - DTP - Mowatt Substation</option>
                          <option>B413 - Bio Science</option>
                          <option>Temporary Boilers</option>
                          <option>General</option>
                        </Form.Control>
                      </Col>
                    </Row>

                    <Row className="mb-4">
                      <Col sm={3}>
                        <Form.Label>Content Type</Form.Label>
                      </Col>
                      <Col sm={9}>
                        <Form.Control
                          as="select"
                          value={selectedContentType}
                          onChange={(e) =>
                            setSelectedContentType(e.target.value)
                          }
                          className="shadow-sm"
                        >
                          <option value="">Select Content Type</option>
                          <option>Image</option>
                          <option>Video</option>
                          <option>Audio</option>
                          <option>Voice Note</option>
                          <option>Document</option>
                          <option>PDF</option>
                        </Form.Control>
                      </Col>
                    </Row>

                    <Row className="mb-4">
                      <Col sm={3}>
                        <Form.Label>Upload Files</Form.Label>
                      </Col>
                      <Col sm={9}>
                        <Form.Control
                          type="file"
                          multiple
                          ref={fileInputRef}
                          onChange={handleFileChange}
                          className="shadow-sm"
                        />
                      </Col>
                    </Row>

                    <Row className="mb-4">
                      <Col sm={3}>
                        <Button
                          style={{
                            backgroundColor: "#ff5733",
                            color: "#fff",
                            border: "none",
                          }} // Custom color
                          variant="primary"
                          onClick={handleAddFiles}
                          disabled={!selectedFiles.length || isSubmitted}
                          className="shadow-sm"
                        >
                          Add Files
                        </Button>
                      </Col>
                    </Row>
                  </div>

                  <div className="border rounded p-3 my-2 bg-light shadow-sm">
                    {/* Header Section with Expand/Collapse Button */}
                    <div className="d-flex justify-content-between align-items-center">
                      <h5
                        className="mb-0 text-primary"
                        style={{ cursor: "pointer" }}
                        onClick={toggleExpand}
                      >
                        Media Files
                        <span className="ms-2 badge bg-info">
                          {mediaFiles?.length || 0}
                        </span>{" "}
                        {/* Display file count */}
                        <p className="fst-italic text-secondary mb-0">
                          *Note - To add/edit tags type the comma separated
                          values and click on add tag button
                        </p>
                      </h5>
                      <Button
                        variant="link"
                        className="text-decoration-none text-primary fw-bold"
                        onClick={toggleExpand}
                      >
                        {isExpanded ? "Collapse" : "Expand"}
                      </Button>
                    </div>

                    {/* Conditionally Render Media Files */}
                    {isExpanded && (
                      <div className="mt-3">
                        {mediaFiles?.map((file, index) => (
                          <div
                            key={index}
                            className="d-flex flex-column mb-3 p-3 border-bottom"
                          >
                            {/* Number for Each Record */}
                            <div className="mb-2">
                              <strong>Record #{index + 1}</strong>{" "}
                              {/* Display the index number */}
                            </div>

                            {/* File Info */}
                            <div className="d-flex justify-content-between mb-2">
                              <div className="d-flex flex-column">
                                <div className="fw-bold">
                                  {getFileNameFromUrl(file?.file_path)}
                                </div>
                                <div className="text-muted">
                                  {file?.contentType || file?.content_type}
                                </div>
                              </div>
                              <div className="d-flex flex-column align-items-end">
                                <div className="fw-bold">
                                  {file?.file?.name}
                                </div>
                                <div className="text-muted">
                                  {(file?.size / (1024 * 1024)).toFixed(2)} MB
                                </div>
                              </div>
                            </div>

                            {/* Media ID Display */}
                            <div className="mb-2">
                              <strong>Media ID: </strong>
                              {file?.media_id ? file.media_id : "New"}
                            </div>

                            {/* Actions */}
                            <div className="d-flex justify-content-end mb-2">
                              <i
                                className="bi bi-eye-fill text-info fs-5 me-3"
                                style={{
                                  cursor: "pointer",
                                  opacity: file.isEditable ? 1 : 0.5,
                                }}
                                title="View Image"
                                onClick={() => {
                                  setSelectedFile(file.file_path);
                                  toggleModal();
                                }}
                              ></i>
                              <i
                                className="bi bi-trash-fill text-danger fs-5"
                                style={{ cursor: "pointer" }}
                                onClick={() => handleDeleteFile(index)}
                                title="Delete File"
                              ></i>
                            </div>

                            {/* Description */}
                            <div className="d-flex flex-column mb-3">
                              <h6 className="fw-bold">Description</h6>
                              <Form.Control
                                as="textarea"
                                rows={3}
                                value={file.media_description}
                                onChange={(e) =>
                                  handleFileDescriptionChange(index, e)
                                }
                                className="form-control-sm mb-2"
                              />
                            </div>

                            {/* Tags */}
                            {/* <div className="d-flex flex-column mb-3">
                              <h6 className="fw-bold">Tags</h6>
                              <div className="d-flex align-items-center mb-2">
                                <Form.Control
                                  type="text"
                                  placeholder="Add tags..."
                                  value={tagsInputs[index]} // Use unique input for each file
                                  onChange={(e) =>
                                    handleTagsInputChange(index, e)
                                  } // Unique handler
                                  className="form-control-sm me-2"
                                />
                                <Button
                                  style={{
                                    backgroundColor: "#ff5733",
                                    color: "#fff",
                                    border: "none",
                                  }}
                                  variant="primary"
                                  size="sm"
                                  onClick={() => handleAddTags(index)}
                                >
                                  +
                                </Button>
                              </div>
                              <div className="d-flex flex-wrap">
                                {file.tags.map((tag, i) => (
                                  <span
                                    key={i}
                                    className="badge bg-secondary me-1 mb-1 d-flex align-items-center"
                                  >
                                    {tag}
                                    <i
                                      className="bi bi-x ms-1"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => handleRemoveTag(index, i)}
                                    ></i>
                                  </span>
                                ))}
                              </div>
                            </div> */}
                            <div className="d-flex flex-column mb-3">
                              <h6 className="fw-bold">Tags</h6>

                              {/* Multiline Input for Tags */}
                              <div className="d-flex align-items-start mb-2">
                                <Form.Control
                                  as="textarea" // Change input to multiline textarea
                                  rows={3} // Allows multi-line typing
                                  placeholder="Add tags, separated by commas..."
                                  value={tagsInputs[index]} // Use a unique input for each file
                                  onChange={(e) =>
                                    handleTagsInputChange(index, e)
                                  } // Unique handler
                                  className="form-control-sm me-2"
                                  style={{ resize: "none", width: "100%" }} // Optional: Prevent resizing
                                />
                                <Button
                                  style={{
                                    backgroundColor: "#ff5733",
                                    color: "#fff",
                                    border: "none",
                                    height: "38px",
                                  }}
                                  variant="primary"
                                  size="sm"
                                  onClick={() => handleAddTags(index)}
                                >
                                  +
                                </Button>
                              </div>

                              {/* Tags Display Section */}
                              <div
                                style={{
                                  width: "100%", // Matches the input width
                                  maxHeight: "120px", // Fixed height for scrollable display
                                  overflowY: "auto", // Scroll when too many tags
                                  border: "1px solid #ddd",
                                  padding: "8px",
                                  borderRadius: "4px",
                                  backgroundColor: "#f9f9f9",
                                  whiteSpace: "pre-wrap", // Allows multiline tag display
                                }}
                              >
                                {file.tags.length > 0 ? (
                                  file.tags.map((tag, i) => (
                                    <div
                                      key={i}
                                      className="d-flex justify-content-between align-items-center mb-1"
                                    >
                                      <span
                                        style={{
                                          wordWrap: "break-word",
                                          flex: "1",
                                        }}
                                      >
                                        {tag}
                                      </span>
                                      <i
                                        className="bi bi-x text-danger ms-2"
                                        style={{ cursor: "pointer" }}
                                        onClick={() =>
                                          handleRemoveTag(index, i)
                                        }
                                      ></i>
                                    </div>
                                  ))
                                ) : (
                                  <small className="text-muted">
                                    No tags added yet
                                  </small>
                                )}
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </fieldset>

                <div className="d-flex justify-content-end">
                  <Button
                    style={{
                      backgroundColor: "#ff5733",
                      color: "#fff",
                      border: "none",
                    }} // Custom color
                    onClick={handleSubmit}
                    disabled={!mediaFiles.length || isSubmitted}
                    type="file"
                    multiple
                    onChange={handleFileChange}
                    ref={fileInputRef} // Attach the ref here
                  >
                    Update Record
                  </Button>
                </div>

                <Form.Group className="mt-3"></Form.Group>
              </Form>

              {/* Loading Popup Modal */}
              <Modal
                show={showLoadingPopup}
                onHide={() => setShowLoadingPopup(false)}
              >
                <Modal.Header closeButton>
                  <Modal.Title>Loading...</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <p>{loadingMessage}</p>
                </Modal.Body>
              </Modal>

              <Modal show={modal} onHide={() => toggleModal(false)}>
                <Modal.Header closeButton>
                  {/* <Modal.Title>Loading...</Modal.Title> */}
                  {selectedFile
                    ? selectedFile?.split("/").pop()
                    : "File Preview"}
                </Modal.Header>
                <Modal.Body>
                  {selectedFile && (
                    <div>
                      {console.log(selectedFile)}
                      {selectedFile?.endsWith(".pdf") && (
                        <embed src={selectedFile} width="100%" height="600px" />
                      )}
                      {selectedFile?.match(/\.(jpg|png|tiff|PNG)$/) && (
                        <img
                          src={selectedFile}
                          alt="Preview"
                          style={{ width: "100%", height: "auto" }}
                        />
                      )}
                      {selectedFile?.endsWith(".mp4") && (
                        <video
                          controls
                          style={{ width: "100%" }}
                          src={selectedFile}
                        />
                      )}
                      {selectedFile?.endsWith(".docx") ||
                        (selectedFile?.endsWith(".pptx") && (
                          <iframe
                            src={`https://view.officeapps.live.com/op/view.aspx?src=${encodeURIComponent(
                              selectedFile
                            )}`}
                            width="100%"
                            height="600px"
                          />
                        ))}

                      {selectedFile.endsWith(".pdf") && (
                        <iframe
                          src={`https://docs.google.com/viewer?url=${encodeURIComponent(
                            selectedFile
                          )}&embedded=true`}
                          width="100%"
                          height="800px"
                        ></iframe>
                      )}
                    </div>
                  )}
                </Modal.Body>
              </Modal>

              <Modal isOpen={modal} toggle={toggleModal} size="lg">
                <ModalHeader toggle={toggleModal}>
                  {/* {selectedFile
                    ? selectedFile?.file_path?.split("/").pop()
                    : "File Preview"} */}
                </ModalHeader>
                <ModalBody>
                  {/* {selectedFile && (
                    <div>
                      {selectedFile?.file_path?.endsWith(".pdf") && (
                        <embed
                          src={selectedFile.file_path}
                          width="100%"
                          height="600px"
                        />
                      )}
                      {selectedFile?.file_path?.match(
                        /\.(jpg|png|tiff|PNG)$/
                      ) && (
                        <img
                          src={selectedFile.file_path}
                          alt="Preview"
                          style={{ width: "100%", height: "auto" }}
                        />
                      )}
                      {selectedFile?.file_path?.endsWith(".mp4") && (
                        <video
                          controls
                          style={{ width: "100%" }}
                          src={selectedFile.file_path}
                        />
                      )}
                      {selectedFile?.file_path?.endsWith(".docx") ||
                        selectedFile?.file_path?.endsWith(".pptx") ||
                        (selectedFile?.file_path?.endsWith(".pdf") && (
                          <iframe
                            src={`https://view.officeapps.live.com/op/view.aspx?src=${encodeURIComponent(
                              selectedFile.file_path
                            )}`}
                            width="100%"
                            height="600px"
                          />
                        ))}
                    </div>
                  )} */}
                </ModalBody>
                <ModalFooter>
                  <Button color="secondary" onClick={toggleModal}>
                    Close
                  </Button>
                </ModalFooter>
              </Modal>
            </Container>
          )}

          {/* Table Tab */}
          {activeTab === 0 && (
            <Box sx={{ width: "100%", padding: 2 }}>
              <Typography variant="h6" gutterBottom>
                Knowledge Base Entries
              </Typography>

              {/* Filters */}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: 2,
                }}
              >
                <TextField
                  label="Search"
                  variant="outlined"
                  fullWidth
                  value={searchFilter}
                  onChange={handleSearchChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">🔍</InputAdornment>
                    ),
                  }}
                />

                <FormControl variant="outlined" sx={{ width: "150px" }}>
                  <InputLabel>Location</InputLabel>
                  <Select
                    value={locationFilter}
                    onChange={handleLocationChange}
                    label="Location"
                    fullWidth
                  >
                    <MenuItem value="">All</MenuItem>
                    <MenuItem value="B115 - DTP - AV Williams">
                      B115 - DTP - AV Williams
                    </MenuItem>
                    <MenuItem value="B001 - CEP">B001 - CEP</MenuItem>
                    <MenuItem value="B046 - DTP Marie Mount">
                      B046 - DTP Marie Mount
                    </MenuItem>
                    <MenuItem value="B224 - DTP Atlantic">
                      B224 - DTP Atlantic
                    </MenuItem>
                    <MenuItem value="B250 - DTP - Leonardtown">
                      B250 - DTP - Leonardtown
                    </MenuItem>
                    <MenuItem value="B068 - DTP Eppley">
                      B068 - DTP Eppley
                    </MenuItem>
                    <MenuItem value="B435 - Prince Frederick">
                      B435 - Prince Frederick
                    </MenuItem>
                    <MenuItem value="B073 - DTP HJ Patterson">
                      B073 - DTP HJ Patterson
                    </MenuItem>
                    <MenuItem value="B226 - DTP - Edward St. John">
                      B226 - DTP - Edward St. John
                    </MenuItem>
                    <MenuItem value="B392 - DTP 3">B392 - DTP 3</MenuItem>
                    <MenuItem value="B067 - DTP 2">B067 - DTP 2</MenuItem>
                    <MenuItem value="B419 - DTP Oakland">
                      B419 - DTP Oakland
                    </MenuItem>
                    <MenuItem value="B416 - DTP - Tawes Hall">
                      B416 - DTP - Tawes Hall
                    </MenuItem>
                    <MenuItem value="B416 - DTP 5">B416 - DTP 5</MenuItem>
                    <MenuItem value="B405 - DTP - Benjamin">
                      B405 - DTP - Benjamin
                    </MenuItem>
                    <MenuItem value="B019 - DTP 1">B019 - DTP 1</MenuItem>
                    <MenuItem value="B405 - DTP4">B405 - DTP4</MenuItem>
                    <MenuItem value="B144 - DTP - Bio Psych">
                      B144 - DTP - Bio Psych
                    </MenuItem>
                    <MenuItem value="B400 - DTP - Mowatt Substation">
                      B400 - DTP - Mowatt Substation
                    </MenuItem>
                    <MenuItem value="B413 - Bio Science">
                      B413 - Bio Science
                    </MenuItem>
                    <MenuItem value="Temporary Boilers">
                      Temporary Boilers
                    </MenuItem>
                    <MenuItem value="General">General</MenuItem>

                    {/* Add other location options */}
                  </Select>
                </FormControl>

                <FormControl variant="outlined" sx={{ width: "150px" }}>
                  <InputLabel>Category</InputLabel>
                  <Select
                    value={categoryFilter}
                    onChange={handleCategoryChange}
                    label="Category"
                    fullWidth
                  >
                    <MenuItem value="">All</MenuItem>
                    <MenuItem value="SOPs">SOPs</MenuItem>
                    <MenuItem value="Workflows">Workflows</MenuItem>
                    <MenuItem value="Incidents">Incidents</MenuItem>
                    <MenuItem value="Manuals">Manuals</MenuItem>
                    <MenuItem value="Knowledge">Knowledge</MenuItem>
                    {/* Add other category options */}
                  </Select>
                </FormControl>
              </Box>

              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>ID</TableCell>
                      <TableCell>Title</TableCell>
                      <TableCell>Category</TableCell>
                      <TableCell>Location</TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredData
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((item) => (
                        <TableRow key={item.id}>
                          <TableCell>
                            {highlightText(
                              item.knowledge_base_id,
                              searchFilter
                            )}
                          </TableCell>
                          <TableCell>
                            {highlightText(item.title, searchFilter)}
                          </TableCell>
                          <TableCell>
                            {highlightText(item.category, searchFilter)}
                          </TableCell>
                          <TableCell>
                            {highlightText(item.location, searchFilter)}
                          </TableCell>
                          <TableCell>
                            <Button
                              onClick={() => {
                                console.log(item);
                                handleEdit(item.knowledge_base_id);
                              }}
                              variant="contained"
                              color="primary"
                            >
                              Edit
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: 2,
                }}
              ></Box>

              {/* Success Toast */}
              <Toast
                show={showToast}
                onClose={() => setShowToast(false)}
                style={{
                  position: "fixed",
                  bottom: 20,
                  right: 20,
                  zIndex: 1,
                }}
              >
                <Toast.Header>
                  <strong className="me-auto">Success</strong>
                </Toast.Header>
                <Toast.Body>
                  Knowledge Base Record Updated successfully!
                </Toast.Body>
              </Toast>

              {/* Pagination */}
              <div className="d-flex justify-content-end">
                {/* Pagination */}
                <Pagination
                  count={Math.ceil(filteredData.length / rowsPerPage)}
                  page={page + 1}
                  onChange={(e, newPage) => setPage(newPage - 1)}
                />
              </div>
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

export default EditKnowledgeBase;

import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Typography,
  TextField,
  IconButton,
  Grid,
  Pagination,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
} from "@mui/material";
import { Visibility, Link, Sort } from "@mui/icons-material";
import "./KnowledgeBaseTable.css";

const KnowledgeBaseTable = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [sortedData, setSortedData] = useState([]);
  const [sortConfig, setSortConfig] = useState({
    key: "title",
    direction: "asc",
  });
  const [locationFilter, setLocationFilter] = useState("");
  const [locations, setLocations] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    // const fetchData = async () => {
    //   try {
    //     const response = await axios.get('http://localhost:5001/api/ViewKnowledge_base');
    //     const groupedData = groupData(response.data.data);
    //     setData(groupedData);
    //     setLocations(getUniqueLocations(groupedData));
    //     setLoading(false);
    //   } catch (error) {
    //     console.error('Error fetching data:', error);
    //     setLoading(false);
    //   }
    // };
    // Static location list

    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://kb.telepresenz.com/api/ViewKnowledge_base"
        );
        if (!response.data || !response.data.data)
          throw new Error("Invalid response data");
        const groupedData = groupData(response.data.data);
        setData(groupedData);
        setLocations(getUniqueLocations(groupedData));
      } catch (error) {
        console.error("Error fetching data:", error.message);
        setError("Failed to load data");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const groupData = (data) => {
    const grouped = [];

    data.forEach((item) => {
      item.media = item.media || [];

      const existingGroup = grouped.find(
        (group) => group.knowledge_base_id === item.knowledge_base_id
      );

      if (existingGroup) {
        existingGroup.media.push(item);
      } else {
        grouped.push({
          knowledge_base_id: item.knowledge_base_id,
          title: item.title,
          location: item.location,
          media: [item],
        });
      }
    });
    return grouped;
  };

  const staticLocations = [
    "B115 - DTP - AV Williams",
    "B001 - CEP",
    "B046 - DTP Marie Mount",
    "B224 - DTP Atlantic",
    "B250 - DTP - Leonardtown",
    "B068 - DTP Eppley",
    "B435 - Prince Frederick",
    "B073 - DTP HJ Patterson",
    "B226 - DTP - Edward St. John",
    "B392 - DTP 3",
    "B067 - DTP 2",
    "B419 - DTP Oakland",
    "B416 - DTP - Tawes Hall",
    "B416 - DTP 5",
    "B405 - DTP - Benjamin",
    "B019 - DTP 1",
    "B405 - DTP4",
    "B144 - DTP - Bio Psych",
    "B400 - DTP - Mowatt Substation",
    "B413 - Bio Science",
    "Temporary Boilers",
    "General",
  ];

  const getUniqueLocations = (data) => {
    const locations = data.map((item) => item.location);
    return Array.from(new Set(locations));
  };

  const handleViewDetails = (item) => {
    window.open(`/detailsgrid/${item.knowledge_base_id}`, "_blank");
  };

  const handleCopyLink = (item) => {
    const link = `${window.location.origin}/detailsgrid/${item.knowledge_base_id}`;
    navigator.clipboard
      .writeText(link)
      .then(() => {
        alert("Link copied to clipboard");
      })
      .catch((err) => {
        alert("Failed to copy the link");
      });
  };

  // const filteredData = data.filter((item) => {
  //   return (
  //     (locationFilter === '' || item.location === locationFilter) &&
  //     Object.values(item).some((value) =>
  //       value && value.toString().toLowerCase().includes(searchQuery.toLowerCase())
  //     )
  //   );
  // });
  // const filteredData = data.filter((item) => {
  //   const flatItemString = JSON.stringify(item).toLowerCase(); // Flatten the entire object to a string
  //   return (
  //     (locationFilter === '' || item.location === locationFilter) &&
  //     flatItemString.includes(searchQuery.toLowerCase()) // Search across all fields
  //   );
  // });
  // const filteredData = data.filter((item) => {
  //   const searchFields = [
  //     item.title,
  //     item.location,
  //     item.media_description,
  //     item.media?.map(mediaItem => mediaItem.type).join(', '), // type column
  //     item.media?.map(mediaItem =>
  //       Array.isArray(mediaItem.tags)
  //         ? mediaItem.tags.join(', ')
  //         : mediaItem.tags
  //     ).join(', '), // tags column
  //   ];

  //   return (
  //     (locationFilter === '' || item.location === locationFilter) &&
  //     searchFields.some((field) =>
  //       field && field.toString().toLowerCase().includes(searchQuery.toLowerCase())
  //     )
  //   );
  // });

  // const filteredData = data.filter((item) => {
  //   const searchFields = [
  //     item.title || '',
  //     item.location || '',
  //     item.fullDescription || '',
  //     item.media?.map(mediaItem => mediaItem.description).join(' ') || '', // Full media_description search
  //     item.media?.map(mediaItem => mediaItem.type).join(' ') || '',
  //     item.media?.map(mediaItem =>
  //       Array.isArray(mediaItem.tags)
  //         ? mediaItem.tags.join(' ')
  //         : mediaItem.tags
  //     ).join(' ') || '', // Full tags search
  //   ];

  //   return (
  //     (locationFilter === '' || item.location === locationFilter) &&
  //     searchFields.some((field) =>
  //       field.toLowerCase().includes(searchQuery.toLowerCase())
  //     )
  //   );
  // });
  const filteredData = data.filter((item) => {
    // Extract search query parts: split by comma
    const searchParts = searchQuery
      .split(",")
      .map((part) => part.trim().toLowerCase())
      .filter((part) => part.length > 0); // Remove empty parts

    // Extract searchable fields
    const searchFields = [
      item.title || "",
      item.location || "",
      item.fullDescription || "",
      item.media?.map((mediaItem) => mediaItem.description).join(" ") || "",
      item.media?.map((mediaItem) => mediaItem.type).join(" ") || "",
      item.media
        ?.map((mediaItem) => {
          // Normalize tags: ensure splitting and trimming
          const tags = String(mediaItem.tags || "")
            .split(",")
            .map((tag) => tag.trim().toLowerCase());
          return tags.join(" "); // Join normalized tags
        })
        .join(" ") || "",
    ];

    // Check if searchParts exist in any searchField
    return (
      (locationFilter === "" || item.location === locationFilter) &&
      searchParts.every((queryPart) =>
        searchFields.some((field) => field.includes(queryPart))
      )

      // return (
      //   (locationFilter === '' || item.location === locationFilter) &&
      //   Object.values(item).some((value) =>
      //     value && value.toString().toLowerCase().includes(searchQuery.toLowerCase())
      //   )
    );
  });

  const handleSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
  };

  const sortData = (data) => {
    return data.sort((a, b) => {
      const valueA = a[sortConfig.key];
      const valueB = b[sortConfig.key];

      // Always sort 'created_time' by the latest first (descending order)
      if (sortConfig.key === "created_time") {
        const dateA = new Date(valueA);
        const dateB = new Date(valueB);
        return sortConfig.direction === "asc" ? dateA - dateB : dateB - dateA;
      }

      // For other fields, perform regular sorting
      if (valueA < valueB) {
        return sortConfig.direction === "asc" ? -1 : 1;
      }
      if (valueA > valueB) {
        return sortConfig.direction === "asc" ? 1 : -1;
      }
      return 0;
    });
  };

  const sortedAndFilteredData = sortData(filteredData);

  const filteredAndSortedData = sortedAndFilteredData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  // Function to highlight the searched text
  // const highlightText = (text) => {
  //   if (!searchQuery) return text;
  //   const regex = new RegExp(`(${searchQuery})`, 'gi');
  //   return text.split(regex).map((part, index) =>
  //     part.toLowerCase() === searchQuery.toLowerCase() ? <span key={index} style={{ backgroundColor: 'yellow' }}>{part}</span> : part
  //   );
  // };
  // const highlightText = (text) => {
  //   if (!text) return ''; // Safeguard against null/undefined
  //   if (!searchQuery) return text; // If there's no search query, return the text as-is

  //   const regex = new RegExp(`(${searchQuery})`, 'gi');
  //   return text.split(regex).map((part, index) =>
  //     part.toLowerCase() === searchQuery.toLowerCase() ?
  //     <span key={index} style={{ backgroundColor: 'yellow' }}>{part}</span> :
  //     part
  //   );
  // };

  const highlightText = (text) => {
    if (!text) return ""; // Safeguard against null/undefined
    if (!searchQuery) return text; // If there's no search query, return the text as-is

    const regex = new RegExp(`(${searchQuery})`, "gi");
    return text.split(regex).map((part, index) =>
      part.toLowerCase() === searchQuery.toLowerCase() ? (
        <span key={index} style={{ backgroundColor: "yellow" }}>
          {part}
        </span>
      ) : (
        part
      )
    );
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div
      style={{
        padding: "20px",
        backgroundColor: "#F1F3F6",
        minHeight: "100vh",
      }}
    >
      <Typography
        variant="h4"
        gutterBottom
        align="center"
        style={{ color: "#2C3E50", fontWeight: "bold" }}
      >
        Knowledge Base Dashboard
      </Typography>

      {/* Search Bar and Location Filter */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "20px",
        }}
      >
        <TextField
          label="Search"
          variant="outlined"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          sx={{
            flex: 1,
            marginRight: "10px",
            backgroundColor: "#ffffff",
            borderRadius: "5px",
          }}
        />
        {
          /* <FormControl variant="outlined" sx={{ minWidth: 120, backgroundColor: '#ffffff', borderRadius: '5px' }}>
          <InputLabel>Location</InputLabel>
          <Select
            value={locationFilter}
            onChange={(e) => setLocationFilter(e.target.value)}
            label="Location"
          >


            <MenuItem value="">All</MenuItem>
            {locations.concat(['DTP AV Williams','DTP Bio Science','CEP','DTP4','B046 - DTP Marie Mount','B224 - DTP Atlantic','B250 - DTP Lennart Town','B068 - DTO Eppley Rec Center','B425 - DTP Prince Frederick','B070- DTP HJ Patterson','B226 - DTP Edward Saint John','B393 - DTP 3','B607 - DTP 2','B419 - DTP Oakland','V141 - DTP Tawes Hall','B416 - DTP 5','DTP B250','DTP Oakland Hall','DTP Tawes Hall (416)','East Side of Tawes Hall','B143 - DTP Benjamin Hall','B019 - DTP 1']).map((location, index) => (
              <MenuItem key={index} value={location}>{location}</MenuItem>
            ))}
          </Select>
        </FormControl> */
          <FormControl
            variant="outlined"
            sx={{
              minWidth: 120,
              backgroundColor: "#ffffff",
              borderRadius: "5px",
            }}
          >
            <InputLabel>Location</InputLabel>
            <Select
              value={locationFilter}
              onChange={(e) => setLocationFilter(e.target.value)}
              label="Location"
            >
              <MenuItem value="">All</MenuItem>
              {staticLocations.map((location, index) => (
                <MenuItem key={index} value={location}>
                  {location}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        }
      </Box>

      <div className="knowledgebase-table">
        <table className="table">
          <thead className="table-header">
            <tr>
              <th style={{ display: "none" }}>#</th>
              <th style={{ display: "none" }}>Knowledge Base ID</th>
              <th>
                <span
                  onClick={() => handleSort("title")}
                  style={{
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  Title
                  <Sort style={{ marginLeft: "5px" }} />
                </span>
              </th>
              <th>
                <span
                  onClick={() => handleSort("location")}
                  style={{
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  Location
                  <Sort style={{ marginLeft: "5px" }} />
                </span>
              </th>
              <th>Type</th>
              <th>Description</th>
              <th>Tags</th>
              {/* <th>Transcription</th> */}
              <th>
                <span
                  onClick={() => handleSort("created_time")}
                  style={{
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  Created Time
                  <Sort style={{ marginLeft: "5px" }} />
                </span>
              </th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredAndSortedData.length > 0 ? (
              filteredAndSortedData.map((item, index) => (
                <React.Fragment key={item.knowledge_base_id}>
                  {item.media &&
                    item.media.length > 0 &&
                    item.media.map((mediaItem, mediaIndex) => (
                      <tr
                        className={`table-row ${
                          mediaIndex % 2 === 0 ? "even" : "odd"
                        }`}
                        key={mediaItem.knowledge_base_id + mediaIndex}
                      >
                        {mediaIndex === 0 ? (
                          <td
                            rowSpan={item.media.length}
                            style={{ display: "none" }}
                          >
                            {index + 1}
                          </td>
                        ) : null}
                        {mediaIndex === 0 ? (
                          <td
                            rowSpan={item.media.length}
                            style={{ display: "none" }}
                          >
                            {item.knowledge_base_id}
                          </td>
                        ) : null}
                        {mediaIndex === 0 ? (
                          <td
                            rowSpan={item.media.length}
                            className="table-title"
                          >
                            {highlightText(item.title)}
                          </td>
                        ) : null}
                        {mediaIndex === 0 ? (
                          <td
                            rowSpan={item.media.length}
                            className="table-location"
                          >
                            {highlightText(item.location)}
                          </td>
                        ) : null}
                        <td>{highlightText(mediaItem.content_type)}</td>
                        <td>{highlightText(mediaItem.media_description)}</td>
                        {/* <td>{Array.isArray(mediaItem.tags) ? mediaItem.tags.join(', ') : mediaItem.tags}</td> */}
                        <td>
                          {item.media?.map((mediaItem, index) => (
                            <span key={index}>
                              {highlightText(
                                Array.isArray(mediaItem.tags)
                                  ? mediaItem.tags.join(", ")
                                  : mediaItem.tags
                              )}
                            </span>
                          ))}
                        </td>

                        {/* <td>{mediaItem.transcription_file}</td> */}
                        <td>{mediaItem.created_time}</td>
                        <td>
                          <Grid container spacing={1} alignItems="center">
                            <Grid item>
                              <IconButton
                                onClick={() => handleViewDetails(item)}
                                color="primary"
                                className="action-button"
                              >
                                <Visibility />
                              </IconButton>
                              <Typography
                                variant="body2"
                                sx={{ display: "inline", marginLeft: "5px" }}
                              >
                                View
                              </Typography>
                            </Grid>
                            <Grid item>
                              <IconButton
                                onClick={() => handleCopyLink(item)}
                                color="primary"
                                className="action-button"
                              >
                                <Link />
                              </IconButton>
                              <Typography
                                variant="body2"
                                sx={{ display: "inline", marginLeft: "5px" }}
                              >
                                Copy Link
                              </Typography>
                            </Grid>
                          </Grid>
                        </td>
                      </tr>
                    ))}
                </React.Fragment>
              ))
            ) : (
              <tr>
                <td colSpan="10" className="no-data">
                  No data available
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {/* Pagination */}
      <Box
        sx={{ display: "flex", justifyContent: "center", marginTop: "20px" }}
      >
        <Pagination
          count={Math.ceil(filteredData.length / itemsPerPage)}
          page={currentPage}
          onChange={handlePageChange}
        />
      </Box>
    </div>
  );
};

export default KnowledgeBaseTable;

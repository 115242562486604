import React, { useState, useEffect } from "react";
import AWS from "aws-sdk";
import { useNavigate } from "react-router-dom"; // Import useNavigate

const S3Explorer = () => {
  const [folders, setFolders] = useState([]); 
  const [s3Data, setS3Data] = useState([]); 
  const [filteredData, setFilteredData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPrefix, setCurrentPrefix] = useState("kb/Kiewit - Temporary Boilers/"); // Set default folder
  const [loading, setLoading] = useState(false);
  const [folderData, setFolderData] = useState([]); // Store data for selected folder


  const navigate = useNavigate(); // Initialize navigation

  AWS.config.update({
    accessKeyId: "AKIAQFC27JCSVG43FYFM",
    secretAccessKey: "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",
    region: "us-east-1",
  });

  const s3 = new AWS.S3();
  const BUCKET_NAME = "kbase-repo";

  useEffect(() => {
    fetchFolderContents("kb/Kiewit - Temporary Boilers/"); // Load default folder
  }, []);

  useEffect(() => {
    if (searchTerm.trim() === "") {
      setFilteredData(s3Data);
    } else {
      const regex = new RegExp(searchTerm.replace(/\*/g, ".*"), "i"); // Wildcard pattern search
      const filtered = s3Data.filter((item) =>
        regex.test(item.name) || regex.test(item.url || "")
      );
      setFilteredData(filtered);
    }
  }, [searchTerm, s3Data]);
  

  const fetchTopLevelFolders = async () => {
    setLoading(true);
    try {
      const params = { Bucket: BUCKET_NAME, Delimiter: "/" };
      const response = await s3.listObjectsV2(params).promise();

      const folderList =
        response.CommonPrefixes?.map((folder) => ({
          name: folder.Prefix.replace("/", ""),
          prefix: folder.Prefix,
        })) || [];

      setFolders(folderList);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching folders:", error);
      setLoading(false);
    }
  };

  const fetchFolderContents = async (prefix) => {
    setLoading(true);
    try {
      const params = { Bucket: BUCKET_NAME, Prefix: prefix, Delimiter: "/" };
      const response = await s3.listObjectsV2(params).promise();

      const folders =
        response.CommonPrefixes?.map((folder) => ({
          name: folder.Prefix.replace(prefix, "").replace("/", ""),
          type: "folder",
          prefix: folder.Prefix,
        })) || [];

      const files =
        response.Contents?.filter((file) => file.Key !== prefix) // Exclude the folder itself
        .map((file) => ({
          name: file.Key.replace(prefix, ""),
          type: file.Key.endsWith("/") ? "folder" : "file",
          url: `https://${BUCKET_NAME}.s3.${AWS.config.region}.amazonaws.com/${encodeURIComponent(file.Key)}`,
        })) || [];
      
      console.log("Filtered Files:", files);

      const allData = [...folders, ...files];
      setS3Data(allData);
      setFilteredData(allData);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching folder contents:", error);
      setLoading(false);
    }
  };

  const openFolder = (folderPrefix) => {
    setCurrentPrefix(folderPrefix);
    setSearchTerm("");
    fetchFolderContents(folderPrefix);
  };

  const goBack = () => {
    if (!currentPrefix) return;
    const parts = currentPrefix.split("/").filter(Boolean);
    parts.pop();
    const newPrefix = parts.length ? parts.join("/") + "/" : "";
    setCurrentPrefix(newPrefix);
    setS3Data([]);
    if (newPrefix) {
      fetchFolderContents(newPrefix);
    } else {
      fetchTopLevelFolders();
    }
  };

  // Home button logic to check authentication
  // const handleHomeClick = () => {
  //   const user = localStorage.getItem("user"); // Check if the user is logged in
  //   if (user) {
  //     navigate("/home"); // Redirect to dashboard if logged in
  //   } else {
  //     navigate("/home"); // Redirect to login if not logged in
  //   }
  // };
  const handleHomeClick = () => {
    setCurrentPrefix("kb/Kiewit - Temporary Boilers/"); // Reset location filter
    setSearchTerm(""); // Clear search input
    fetchFolderContents("kb/Kiewit - Temporary Boilers/"); // Reload default folder
    navigate("/home"); // Navigate to home
  };
  

  return (
    <div style={{ padding: "20px" }}>
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <h2>S3 Explorer</h2>
        <button 
          onClick={() => {
            window.open("/home", "_self"); // Opens /home in the same tab
            window.close(); // Closes the current tab
          }} 
          style={{
            padding: "8px 12px",
            fontSize: "16px",
            cursor: "pointer",
            background: "#007bff",
            color: "white",
            border: "none",
            borderRadius: "5px",
          }}
        >
          🏠 Home
        </button>
      </div>

      {currentPrefix ? (
        <>
          {/* Search Input */}
          <input
            type="text"
            placeholder="Search files..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            style={{
              marginBottom: "10px",
              padding: "8px",
              width: "100%",
              border: "1px solid #ccc",
              borderRadius: "5px",
            }}
          />
          <button onClick={goBack} style={{ marginBottom: "10px" }}>
            🔙 Go Back
          </button>
        </>
      ) : (
        <h3>Top-Level Folders</h3>
      )}

      {loading ? <p>Loading...</p> : null}

      <div style={{ display: "grid", gridTemplateColumns: "repeat(auto-fill, minmax(150px, 1fr))", gap: "20px" }}>
        {!currentPrefix
          ? folders.map((folder, index) => (
              <div
                key={index}
                style={{
                  textAlign: "center",
                  border: "1px solid #ddd",
                  padding: "10px",
                  borderRadius: "5px",
                  cursor: "pointer",
                  background: "#f0f0f0",
                }}
                onClick={() => openFolder(folder.prefix)}
              >
                <p>📁 {folder.name}</p>
              </div>
            ))
          : filteredData.map((item, index) => (
              <div
                key={index}
                style={{
                  textAlign: "center",
                  border: "1px solid #ddd",
                  padding: "10px",
                  borderRadius: "5px",
                  cursor: "pointer",
                  background: item.type === "folder" ? "#f0f0f0" : "#ffffff",
                }}
                onClick={item.type === "folder" ? () => openFolder(item.prefix) : () => window.open(item.url, "_blank")}
              >
                <p>{item.type === "folder" ? `📁 ${item.name}` : `📄 ${item.name}`}</p>
              </div>
            ))}
      </div>
    </div>
  );
};

export default S3Explorer;
